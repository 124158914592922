@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&family=PT+Sans+Narrow&family=Secular+One&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&family=PT+Sans+Narrow&family=Secular+One&family=Sofia+Sans+Extra+Condensed:wght@300&display=swap');

@import '../css/root/root.css';

.mapouter{position:relative;text-align:right;height:250px;width:auto;}
.gmap_canvas {overflow:hidden;background:none!important;height: 250px;;width:auto;}

.resume{
  background-color: var(--resume-bck-clr);
  color:#e9ecef;
  border-radius: 0.4rem;
  padding: 1.5em;
}
.title{
  font-family: 'Oswald', sans-serif;
  font-size: large;
  text-decoration:underline;
}
.grid-container {
  overflow: auto;
  display: grid;
  gap: 5px;
  grid-template-columns: 64% auto;  
}
.grid-container,h2,h4,h5{
  /* font-family: 'Sofia Sans Extra Condensed', sans-serif; */
  font-family: var(--custome-fnt);
  font-size: 1.5 rem;

}
h4,h5{
  font-weight: bold;
}
.cntct-detail>a{
  color:#e9ecef;
}
.cntct-detail>a:hover{
  color:#e9ecef;
  font-weight: bold;
}
.grid-container >.name>h1 {
  font-size: 2em;
  font-weight: bold;
  color:gold
  /* font-family: 'PT Serif', serif; */
}
.name {
  grid-column: 1 / span 2;
}
.grid-container1 {
  display: grid;
  gap: 1rem;
}
.grid-container1 > div > div {
  padding-left: 0.5rem;
  text-align: justify;
  text-justify: inter-word;
}
.grid-container2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.grid-container2 > div {
  padding-left: 1rem;
  margin-bottom: 0.5rem;
}
.grid-container1 > div>h3{
  font-weight: bold;

}
.grid-container2 > div>h3{
  font-weight: bold;
}