@import "./root/root.css";
.about-home {
  background-color: var(--down-card-background);
  color: rgb(1, 51, 51);
}
.about-heading {
  font-family: var(--home-heading-fnt-1);
}
.about-body {
  font-family: var(--custome-fnt);
  font-size: var(--custome-fnt-size);
  text-align: justify;
  text-justify: inter-word;
}
