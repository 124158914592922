.contact-main {
  margin-bottom: 2.5rem;
  display: grid;
  grid-template-columns: 40% auto;
  background-image: linear-gradient(
    to right,
    rgb(248, 244, 244) 35%,
    rgb(13, 86, 2) 30%,
    rgb(246, 243, 243)
  );
  overflow: auto;
}
.contain-side {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.left-side {
  flex-direction: column;
  justify-content: flex-start;
  color: darkred;
}
a {
  margin-right: 1em;
  text-decoration: none;
  color:black;
}
a:hover{
  color:darkred;
}
.right-side {
  color: white;
}

.form-control {
  margin-bottom: 1em;
}
.contact-form > div,
.btn-contct {
  width: 25em;
}
textarea.form-control {
  min-height: calc(9.5em + 0.75rem + 5px);
}
