@import "./root/root.css";

/* note: to make div sticky in react, only position-sticky will not work. also need to add fixed-top class of bootstrap in the div */

.body {
  /* border:2px solid red; */
  position: relative;
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(
    var(--background-color),
    var(--background-color1)
  );
  /* background-color: var(--background-color,red); */
  color: var(--text-color, yellow);
  overflow: auto;
}
.body::-webkit-scrollbar {
  display: none;
}
.login-form {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.manu-bar {
  position: fixed;
  margin:0;
  padding-right: 0;
  z-index: 2;
}
.home-page {
  position: relative;
  justify-content: space-between;
  margin: 5em 5vw 0 5vw;
}
.home-page > .profile {
  height: auto;
  max-Width: 25vw;
  padding: 0%;
  z-index: 1;
}
.home-page > .welcome {
  padding: 50px 70px;
  max-width:70vw;
  box-sizing: border-box;
  margin-left: -70px;
  margin-top: 35px;
  margin-bottom: 35px;
  border-radius: 10px;
}
.contain{
  margin: var(--margin-body);
}
