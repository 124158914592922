@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@200&display=swap');

:root{
    --margin-body:1.5625vw 5vw 0 5vw;
    
    --top-card-background:#e3d5ca;
    --down-card-background:#489fb5;

    --background-color: #001d3d;
    --background-color1: #caf0f8;
    --singin-btn-bg-color:hsl(191, 100%, 75%);
    --singin-btn-bg-color-hover:hsl(191, 92%, 47%);
    --singup-btn-bg-color:hsl(0, 0%, 83%);
    --singup-btn-bg-color-hover:hsl(0, 1%, 66%);
    --text-color:#caf0f8;


    --home-heading-fnt:'Secular One', sans-serif;
    --home-body-fnt-1:'PT Sans Narrow', sans-serif;
    --custome-fnt:'Catamaran', sans-serif;


    --custome-fnt-size: 1.5rem;

    /* resume var */
    --resume-bck-clr:#354f52;
}